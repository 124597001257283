import React from "react";
import Layout from "../components/Layout";
import Link from "gatsby-link";

const NotFound = () => {
    return (
        <Layout title="Page not found">
            <div className="container error-container d-flex flex-column justify-content-center align-items-center py--15">
                <h1 className="h2 txt--center mb--30">The page you are looking for was not found.</h1>

                <Link to="/" className="btn btn--lg">Go To Dashboard</Link>
            </div>
        </Layout>
    )
}
export default NotFound